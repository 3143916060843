var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{ref:"clientsDetailGeneralForm",staticClass:"mb-4",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form-item',{staticClass:"m0",attrs:{"label":"Nombre"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'client_name',
							{
								rules: [
									{
										required: true,
										message: 'Campo requerido.',
									} ],
							} ]),expression:"[\n\t\t\t\t\t\t\t'client_name',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showControls),expression:"showControls"}],staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('hr',{staticClass:"hrText"})]),_c('div',{staticClass:"col-md-4 text-left"},[_c('a-button',{staticClass:"btn btn-warning",attrs:{"icon":"arrow-left"},on:{"click":_vm.onCancel}},[_vm._v("Regresar")])],1),_c('div',{staticClass:"col-md-8 text-right"},[_c('a-button',{staticClass:"btn btn-success",attrs:{"icon":"save","htmlType":"submit","id":"clientDetailGeneralComponent_submit"}},[_vm._v("Guardar")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }